<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">生产报工</div>
      <div>
        <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="handler" label="报工人">
            <a-select v-model="dataForm.handler" style="width: 100%">
              <a-select-option v-for="item in handlerItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="production_quantity" label="报工数量">
            <a-input-number v-model="dataForm.production_quantity" style="width: 100%" />
          </a-form-model-item>

          <a-form-model-item prop="start_time" label="开始时间">
            <a-date-picker
              v-model="dataForm.start_time"
              placeholder="请选择时间"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item prop="end_time" label="结束时间">
            <a-date-picker
              v-model="dataForm.end_time"
              placeholder="请选择时间"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              style="width: 100%"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { productionRecordCreate } from "@/api/production";
import { userOption } from "@/api/option";

export default {
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        handler: [{ required: true, message: "请选择报告人", trigger: "change" }],
        production_quantity: [{ required: true, message: "请输入生产数量", trigger: "change" }],
      },
      loading: false,
      dataForm: {},
      handlerItems: [],
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          productionRecordCreate(this.dataForm)
            .then((data) => {
              this.$message.success("报工完成");
              this.$emit("create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(value) {
      if (value) {
        userOption({ page_size: 999999, is_active: true }).then((data) => {
          this.handlerItems = data.results;
        });

        this.dataForm = {
          production_task: this.form.id,
          production_quantity: this.form.remain_quantity,
          handler: this.userId,
        };
      }
    },
  },
};
</script>

<style scoped></style>
